import { render, staticRenderFns } from "./Advantage.vue?vue&type=template&id=5365f329&scoped=true&"
import script from "./Advantage.vue?vue&type=script&lang=js&"
export * from "./Advantage.vue?vue&type=script&lang=js&"
import style0 from "./Advantage.vue?vue&type=style&index=0&id=5365f329&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5365f329",
  null
  
)

export default component.exports