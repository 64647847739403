<template>
    <div class="caseChart">
        <div class="ccBox">
            <!-- 顶部标题 -->
            <div class="ccTopTitle">
                <div class="ccBig">我们遵循科学的设计和开发流程</div>
                <div class="ccSmall">
                    威有科技拥有一套详尽的项目流程管控方法，借助项目协作工具，
                    从需求分析，项目组成员的调配，过程产物的严格品控，我们用科学的方法保障项目质量。
                </div>
            </div>
            <!-- 流程图 -->
            <div class="ccLists">
                <ul>
                    <li class="ccLi">
                        <div class="ccLiImg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="ccImg" />
                        </div>
                        <div class="ccLarr">
                            <div class="arrowImg">
                                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg" />
                            </div>
                        </div>
                        <div class="ccLiText">流程图</div>
                    </li>
                    <li class="ccLi">
                        <div class="ccLiImg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="ccImg2" />
                        </div>
                        <div class="ccLarr">
                            <div class="arrowImg">
                                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg" />
                            </div>
                        </div>
                        <div class="ccLiText">需求分析</div>
                        <div class="ccLis">
                            <ul>
                                <li class="cls">调研沟通</li>
                                <li class="cls">方案产生</li>
                                <li class="cls">工期确定</li>
                            </ul>
                            <!-- 遮罩层 -->
                            <div class="ccLis2">
                                <ul>
                                    <li class="cls2">调研沟通</li>
                                    <li class="cls2">方案产生</li>
                                    <li class="cls2">工期确定</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="ccLi">
                        <div class="ccLiImg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="ccImg3" />
                        </div>
                        <div class="ccLarr">
                            <div class="arrowImg">
                                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg" />
                            </div>
                        </div>
                        <div class="ccLiText">产品策划</div>
                        <div class="ccLis">
                            <ul>
                                <li class="cls">线框图/原型图稿</li>
                                <li class="cls">交互模型</li>
                                <li class="cls">规范标准说明</li>
                            </ul>
                            <!-- 遮罩层 -->
                            <div class="ccLis2">
                                <ul>
                                    <li class="cls2">线框图/原型图稿</li>
                                    <li class="cls2">交互模型</li>
                                    <li class="cls2">规范标准说明</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="ccLi">
                        <div class="ccLiImg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="ccImg4" />
                        </div>
                        <div class="ccLarr">
                            <div class="arrowImg">
                                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg" />
                            </div>
                        </div>
                        <div class="ccLiText">界面设计</div>
                        <div class="ccLis">
                            <ul>
                                <li class="cls">色彩运用方案</li>
                                <li class="cls">功能可视化设计</li>
                                <li class="cls">输出标准应用元素</li>
                            </ul>
                            <!-- 遮罩层 -->
                            <div class="ccLis2">
                                <ul>
                                    <li class="cls2">色彩运用方案</li>
                                    <li class="cls2">功能可视化设计</li>
                                    <li class="cls2">输出标准应用元素</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="ccLi">
                        <div class="ccLiImg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="ccImg5" />
                        </div>
                        <div class="ccLarr">
                            <div class="arrowImg">
                                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg" />
                            </div>
                        </div>
                        <div class="ccLiText">开发阶段</div>
                        <div class="ccLis">
                            <ul>
                                <li class="cls">前端开发</li>
                                <li class="cls">数据库及接口开发</li>
                                <li class="cls">后台功能开发</li>
                            </ul>
                            <!-- 遮罩层 -->
                            <div class="ccLis2">
                                <ul>
                                    <li class="cls2">前端开发</li>
                                    <li class="cls2">数据库及接口开发</li>
                                    <li class="cls2">后台功能开发</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="ccLi">
                        <div class="ccLiImg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="ccImg6" />
                        </div>
                        <div class="ccLarr">
                            <div class="arrowImg">
                                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg" />
                            </div>
                        </div>
                        <div class="ccLiText">测试阶段</div>
                        <div class="ccLis">
                            <ul>
                                <li class="cls">功能模块用例测试</li>
                                <li class="cls">兼容性及安全性测试</li>
                                <li class="cls">项目稳定性测试</li>
                            </ul>
                            <div class="ccLis2">
                                <ul>
                                    <li class="cls2">功能模块用例测试</li>
                                    <li class="cls2">兼容性及安全性测试</li>
                                    <li class="cls2">项目稳定性测试</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="ccLi">
                        <div class="ccLiImg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="ccImg7" />
                        </div>
                        <div class="ccLiText">上线试运营</div>
                        <div class="ccLis">
                            <ul>
                                <li class="cls">日常运维响应</li>
                                <li class="cls">BUG反馈及时修改</li>
                            </ul>
                            <div class="ccLis2">
                                <ul>
                                    <li class="cls2">日常运维响应</li>
                                    <li class="cls2">BUG反馈及时修改</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CaseChrat",
};
</script>

<style lang="less" scoped>
.caseChart {
    width: 100%;
    height: 600px;
    // margin-top: 50px;
    background: url("https://www.2000new.com/Public/static/images/service-bg.png") center;
    .ccBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        padding-top: 90px;
        // 顶部标题
        .ccTopTitle {
            width: 60%;
            height: 130px;
            margin: 0 auto;
            color: white;
            .ccBig {
                width: 100%;
                height: 80px;
                line-height: 80px;
                text-align: center;
                font-size: 40px;
                font-weight: 900;
            }
            .ccSmall {
                width: 100%;
                height: 50px;
                line-height: 25px;
                text-align: center;
            }
        }
        // 流程图
        .ccLists {
            width: 100%;
            height: 400px;
            margin-top: 40px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .ccLi {
                    width: 150px;
                    height: 300px;
                    position: relative;
                    .ccLiImg {
                        width: 57%;
                        height: 95px;
                        margin: 0 auto;
                        text-align: center;
                        position: relative;
                        overflow: hidden;
                        .ccImg {
                            position: absolute;
                            top: -220px;
                            left: -348px;
                        }
                        .ccImg2 {
                            position: absolute;
                            top: -220px;
                            left: -430px;
                        }
                        .ccImg3 {
                            position: absolute;
                            top: -220px;
                            left: -516px;
                        }
                        .ccImg4 {
                            position: absolute;
                            top: -220px;
                            left: -605px;
                        }
                        .ccImg5 {
                            position: absolute;
                            top: -220px;
                            left: -690px;
                        }
                        .ccImg6 {
                            position: absolute;
                            top: -220px;
                            left: -775px;
                        }
                        .ccImg7 {
                            position: absolute;
                            top: -220px;
                            left: -857px;
                        }
                    }
                    .ccLarr {
                        width: 110px;
                        height: 2px;
                        position: absolute;
                        top: 47px;
                        left: 115px;
                        background-color: #8292dd;
                        .arrowImg {
                            position: absolute;
                            top: -14px;
                            left: 30px;
                            width: 25px;
                            height: 25px;
                            overflow: hidden;
                            .aiImg {
                                position: absolute;
                                top: -99px;
                                right: -585px;
                            }
                        }
                    }
                    .ccLiText {
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        font-size: 20px;
                        text-align: center;
                        font-weight: 900;
                        color: white;
                    }
                    .ccLis {
                        width: 100%;
                        height: 150px;
                        font-weight: 900;
                        ul {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            .cls {
                                width: 100%;
                                color: gray;
                                text-align: center;
                                transition: 0.5s;
                            }
                        }
                        // 遮罩层
                        .ccLis2 {
                            width: 100%;
                            height: 150px;
                            opacity: 0;
                            transition: 0.5s;
                            position: absolute;
                            top: 125px;
                            left: 40px;
                            color: white;
                            transition: 0.5s;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                .cls2 {
                                    width: 100%;
                                    color: gainsboro;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    .ccLis:hover {
                        .cls {
                            transform: translateX(-80px);

                            opacity: 0;
                        }
                        .ccLis2 {
                            transform: translateX(-40px);

                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
</style>
