<template>
    <!-- 客户案例 -->
    <div class="case">
        <!-- 案例列表 -->
        <CaseList v-show="!show"></CaseList>
        <router-view></router-view>
        <!-- 流程图 -->
        <CaseChart></CaseChart>
        <!-- 技术优势 -->
        <Advantage></Advantage>
    </div>
</template>

<script>
import CaseList from "@/components/content/Case/CaseList/CaseList.vue";
import CaseChart from "@/components/content/Case/CaseChart/CaseChart.vue";
import Advantage from "@/components/content/Case/Advantage/Advantage.vue";

export default {
    name: "Case",
    data() {
        return {
            show: false,
        };
    },
    components: {
        CaseList,
        CaseChart,
        Advantage,
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal) {
                if (newVal.name == "listDetail") {
                    this.show = true;
                } else {
                    this.show = false;
                }
            },
        },
    },
};
</script>

<style lang="less" scoped>
.case {
    margin: 0 auto;
}
</style>
