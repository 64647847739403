<template>
    <div class="caseList">
        <!-- 头部 -->
        <div>
            <div class="clTops">
                <div class="clt1">
                    <a
                        v-if="getAdvData(5).children"
                        :href="getAdvData(5).children[0].url"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div class="c1VBox">
                            <video
                                class="cl1V"
                                preload="auto"
                                autoplay="autoplay"
                                muted="muted"
                                :src="getAdvData(5).children[0].image"
                                poster=""
                                loop=""
                                v-if="getAdvData(5).children[0].image.indexOf('.mp4') > 0"
                            ></video>
                            <img class="cl1V" :src="getAdvData(5).children[0].image" v-else />
                        </div>
                        <div class="cl1Box">
                            <div class="clb1">威有案例故事</div>
                            <div class="clb2">
                                <div class="c2Lis">
                                    <p class="c2l1">9000+</p>
                                    <p class="c2l2">技术伙伴</p>
                                </div>
                                <div class="c2Lis">
                                    <p class="c2l1">100+</p>
                                    <p class="c2l2">全球权威认证</p>
                                </div>
                                <div class="c2Lis">
                                    <p class="c2l1">3,000,000+</p>
                                    <p class="c2l2">客户选择</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="clBBox">
                <div class="clt2">
                    <div class="ct2Box">
                        <div class="c2bL">
                            <div class="c2l1">
                                {{ contentObj.content }}
                                <!-- 威有云 <br />全球300万客户的共同选择 -->
                            </div>
                            <div class="c2l2">
                                <img v-lazy="contentObj.big_image" class="c22Img" />
                            </div>
                            <div class="c2l3">
                                {{ contentObj.remark }}
                            </div>
                            <div class="c2l4" @click="starKF">
                                开始开发
                                <div class="c24Code">
                                    <img :src="getWebData().wechat_visitor" class="c24Img" />
                                </div>
                            </div>
                            <VuForm page="客户案例" ref="form"></VuForm>
                        </div>
                        <!-- logo获取 -->
                        <div class="c2bR">
                            <ul>
                                <li class="c2rLi" v-for="item in img8List" :key="item">
                                    <div @listClick="item.id;">
                                        <img :src="item.logo" alt="" class="c2rlImg" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="clBox">
                    <div class="clBox_top">
                        <!-- <CaseList2 /> -->
                        <listNav @getList="getList"></listNav>
                    </div>

                    <!-- 案例列表 -->
                    <div class="clCaseBox">
                        <!-- <CaseList1 /> -->
                        <listMain :ids="ids"></listMain>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 导入事件总线
import bus from "@/components/common/eventBus.js";
// 导入网络请求库
import axios from "axios";
// 查询字符串解析与字符串化库
import qs from "qs";
// 导入自定义的请求插件
import VuForm from "../../TheContact/vuForm.vue";
import listNav from "./listNav.vue";
import listMain from "./listMain.vue";
// 砖石布局插件,针对瀑布流布局 (但是你已经注释掉了
// import Masonry from "masonry-layout";

export default {
    components: {
        listDetail: () => import("./listDetail"),
        VuForm,
        listNav,
        listMain,
    },
    inject: ["getWebData", "getAdvData", "addUserBehaviorL"],
    name: "CaseList",
    data() {
        return {
            detailShow: true,
            delatilId: "",
            isActive: -1,
            allDetail: [],
            masonry: null,
            caseShow: -1,
            count: 9,
            // 案例头部导航
            buttonT: [],
            listItemShow: [],
            classList: [],
            // 案例列表数据
            theDatas: [],
            // 全部案例列表数据
            theDatas2: [],
            theDatas3: [],
            theDatas4: [],
            theDatas5: [],
            showIndex: 0,
            //前8 id图片数组
            img8List: [],
            ids: {},
            contentObj: {},
        };
    },
    mounted() {
        bus.$on("caseShow", res => {
            this.detailShow = false;
            this.delatilId = res;
            this.$forceUpdate();
        });
        this.getContent();
    },
    created() {
        bus.$on("img8", list => {
            console.log("11111", list);
            this.img8List = list;
        });
    },
    methods: {
        // 点击列表的方法，使用eventBus发出事件通知
        listClick(row) {
            console.log(row);
            bus.$emit("caseShow", row);
        },
        starKF() {
            this.$refs.form.showTcbs();
            this.addUserBehaviorL("客户案例点击开始开发");
        },
        getList(id, fuid) {
            this.ids = { id, fuid };
        },
        async getContent() {
            let { data } = await axios.get("/api/project/introList");
            // 找到那些内容
            this.contentObj = data.find(e => e.id == 4);
        },
    },
};
</script>

<style lang="less">
@keyframes setMargin {
    0% {
        scale: 1;
    }
    100% {
        scale: 1.05;
    }
}
.currtKey {
    display: none;
}
.item:hover {
    .listItemDiv {
        display: block !important;
    }
    // margin-top: 0 !important;
    animation: setMargin 0.1s 1;
    scale: 1.05;
    // background: red;
}
.masonry {
    column-count: 6;
    width: 95%;
    margin: 0 auto;
}
.item {
    // transition:  1s;
    // width: 80%;
    min-height: 100px;
    break-inside: avoid;
    box-sizing: border-box;
    margin-top: 20px !important;
    padding: 0px !important;
}
.myPopper {
    // background: black !important;
    padding: 0px !important;
    width: 99%;
    margin: 0 auto;
}
</style>

<style lang="less">
.clBox_top {
    position: sticky;
    top: 84px;
    // background-color: white;
    // backdrop-filter: blur(15px);
    width: 100%;
    z-index: 99;
    padding-top: 10px;
}
.firstItem {
    margin-top: 0 !important;
}
.grid {
    width: 100%;
}
.grid-sizer,
.grid-item {
    width: 25%;
}
.grid-item {
    float: left;
}
.choseOne {
    background: #7982f1;
    color: #fff;
}
.caseList {
    width: 100%;
    .clTops {
        width: 100%;
        height: 600px;
        .clt1 {
            width: 100%;
            height: 100%;
            // overflow: hidden;
            position: relative;
            & > a {
                height: 100%;
                width: 100%;
                display: block;
                top: 0px;
                position: relative;
            }
            .c1VBox {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: -1;
                overflow: hidden;
                .cl1V {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center top;
                }
            }
            .cl1Box {
                width: 1280px;
                height: 245px;
                margin: 0 auto;
                // margin-top: 260px;
                padding-top: 260px;
                // background-color: rgba(255, 255, 255, 0.5);
                .clb1 {
                    width: 100%;
                    height: 66px;
                    line-height: 66px;
                    text-align: center;
                    font-size: 52px;
                    font-weight: bold;
                    color: white;
                    margin-bottom: 72px;
                }
                .clb2 {
                    width: 100%;
                    height: 89px;
                    color: white;
                    display: flex;
                    justify-content: space-around;
                    .c2Lis {
                        width: 432px;
                        height: 100%;
                        text-align: center;
                        .c2l1 {
                            margin-bottom: 12px;
                            font-size: 40px;
                            line-height: 50px;
                        }
                        .c2l2 {
                            opacity: 0.8;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .clBc {
        width: 100%;
        height: 400px;
        background: url("https://www.vyou168.com/Application/Uploads/2019-12-17/5df832377d25f.png") no-repeat top center;
    }
    .clBBox {
        width: 100%;
        background-color: #fff;
        .clt2 {
            width: 100%;
            height: 633px;
            background-color: #fff;
            .ct2Box {
                width: 90%;
                height: 100%;
                margin: 0 auto;
                overflow: hidden;
                .c2bL {
                    width: 572px;
                    height: 550px;
                    float: left;
                    margin-top: 100px;
                    .c2l1 {
                        width: 100%;
                        height: 112px;
                        line-height: 56px;
                        font-size: 36px;
                        color: #252b3a;
                    }
                    .c2l2 {
                        width: 100%;
                        height: 278px;
                        margin-top: 20px;
                        .c22Img {
                            width: 278px;
                            height: 100%;
                        }
                    }
                    .c2l3 {
                        width: 100%;
                        height: 30px;
                        margin-top: 20px;
                        font-size: 20px;
                        line-height: 30px;
                        color: #575d6c;
                        font-weight: 700;
                    }
                    .c2l4 {
                        width: 122px;
                        height: 40px;
                        line-height: 40px;
                        margin-top: 30px;
                        border-radius: 10px;
                        text-align: center;
                        color: white;
                        background-color: #7781f1;
                        cursor: pointer;
                        position: relative;
                        .c24Code {
                            width: 100px;
                            height: 100px;
                            border-radius: 10px;
                            position: absolute;
                            top: -120px;
                            left: 12px;
                            box-shadow: 1px 1px 10px 1px gainsboro;
                            overflow: hidden;
                            transition: 0.5s;
                            opacity: 0;
                            transform: translateY(50px);
                            .c24Img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .c2l4:hover {
                        .c24Code {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }
                .c2bR {
                    width: 500px;
                    height: 500px;
                    float: right;
                    margin-top: 100px;
                    margin-right: 100px;
                    ul {
                        width: 100px;
                        height: 100%;
                        position: relative;
                        .c2rLi {
                            width: 120px;
                            height: 50px;
                            box-shadow: 1px 1px 10px 1px gainsboro;
                            cursor: pointer;
                            transition: 0.5s;
                            border-radius: 10px;
                            overflow: hidden;
                            .c2rlImg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .c2rLi:hover {
                            transform: translateY(-10px);
                            box-shadow: 1px 1px 10px 1px gray;
                        }
                        .c2rLi:nth-child(1) {
                            position: absolute;
                            top: 30px;
                            left: 50px;
                        }
                        .c2rLi:nth-child(2) {
                            position: absolute;
                            top: 100px;
                            left: 20px;
                        }
                        .c2rLi:nth-child(3) {
                            position: absolute;
                            top: 15px;
                            left: 200px;
                        }
                        .c2rLi:nth-child(4) {
                            position: absolute;
                            top: 120px;
                            left: 180px;
                        }
                        .c2rLi:nth-child(5) {
                            position: absolute;
                            top: 80px;
                            left: 320px;
                        }
                        .c2rLi:nth-child(6) {
                            position: absolute;
                            top: 200px;
                            left: 280px;
                        }
                        .c2rLi:nth-child(7) {
                            position: absolute;
                            top: 250px;
                            left: 110px;
                        }
                        .c2rLi:nth-child(8) {
                            position: absolute;
                            top: 180px;
                            left: 50px;
                        }
                    }
                }
            }
        }
    }
    .cl1V {
        transform: scale(1);
    }
    .clBox {
        max-width: 100%;
        // width: 100%;
        min-height: 950px;
        margin: 0 auto;
        margin-top: 50px;
        // 分类导航
        .clTopTabs {
            max-width: 1400px;
            min-height: 50px;
            position: sticky;
            top: 87px;
            z-index: 8888;
            background-color: #fff;
            .cttUl {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                position: relative;
                .cttLi {
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    margin-top: 10px;
                    text-align: center;
                    border-radius: 20px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    cursor: pointer;
                    margin-bottom: 5px;
                    // 下拉菜单
                    .cttNavigation {
                        // width: 1340px;
                        width: 100%;
                        height: 400px;
                        position: absolute;
                        z-index: 9;
                        top: 50px;
                        left: 0px;
                        border-radius: 10px;
                        color: white;
                        display: none;
                        background-color: #fff;
                        box-shadow: 1px 1px 10px 1px gray;
                        color: black;
                        .ctnLeft {
                            width: 20%;
                            height: 100%;
                            // background: url("vu818.com/qcloud/portal/kit/images/side-bg.a725f079.jpg")
                            //   right bottom no-repeat;
                            float: left;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }
                        .ctnCenter {
                            width: 75%;
                            height: 100%;
                            float: left;
                            margin-left: 2%;
                            ul {
                                width: 100%;
                                min-height: 30px;
                                display: flex;
                                // justify-content: space-around;
                                flex-wrap: wrap;
                                .ctcLi {
                                    min-width: 50px;
                                    height: 30px;
                                    line-height: 30px;
                                    margin-top: 10px;
                                }
                                .ctcLi:nth-child(n + 2) {
                                    margin-left: 15px;
                                }
                                .ctcLi:hover {
                                    font-size: 20px;
                                    font-weight: 900;
                                    color: #7781f1;
                                }
                                .cTexts {
                                    color: #7781f1;
                                    font-size: 20px;
                                    font-weight: 900;
                                }
                            }
                        }
                    }
                }
                .cttLi:hover {
                    .cttNavigation {
                        display: block;
                    }
                }
                .cttActive {
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    color: white;
                }
            }
        }
        .caseList .clTops .clt1 {
            height: 650px;
        }

        // 案例列表
        .clCaseBox {
            width: 100%;
            height: 100%;
            margin-top: 30px;
            .claseList {
                width: 100%;
                height: 100%;
                ul {
                    width: 100%;
                    min-height: 250px;
                    display: flex;
                    // justify-content: space-around;
                    flex-wrap: wrap;
                    overflow-y: hidden;
                    .clBg {
                        width: 100%;
                        height: 100%;
                    }
                    // .clcLi {
                    //   width: 28%;
                    //   height: 250px;
                    //   position: relative;
                    //   border-radius: 20px;
                    //   box-shadow: 1px 1px 10px 1px gainsboro;
                    //   margin-top: 30px;
                    //   margin-bottom: 5px;
                    //   transition: 0.5s;
                    //   overflow: hidden;
                    //   float: left;
                    //   margin-left: 55px;
                    //   .clBg {
                    //     width: 100%;
                    //     height: 100%;
                    //     .bgImg {
                    //       border-radius: 20px;
                    //       width: 100%;
                    //       height: 100%;
                    //     }
                    //   }
                    //   .cldnImg {
                    //     width: 120px;
                    //     height: 70px;
                    //     position: absolute;
                    //     bottom: 10px;
                    //     right: 10px;

                    //     .cdiImg {
                    //       width: 100%;
                    //       height: 100%;
                    //     }
                    //   }
                    //   .clMask {
                    //     width: 100%;
                    //     height: 250px;
                    //     background-color: #e4e7ed70;
                    //     opacity: 0;
                    //     text-align: center;
                    //     border-radius: 20px;
                    //     position: absolute;
                    //     top: 0px;
                    //     transition: 0.5s;
                    //     .cmCode {
                    //       width: 120px;
                    //       height: 120px;
                    //       margin-top: 50px;
                    //       border-radius: 20px;
                    //     }
                    //     .cmP {
                    //       text-align: center;
                    //       // color: white;
                    //       font-weight: 900;
                    //       font-size: 30px;
                    //     }
                    //   }
                    // }
                    // .clcLi2 {
                    //   width: 28%;
                    //   height: 250px;
                    //   position: relative;
                    //   border-radius: 20px;
                    //   margin-top: 30px;
                    //   margin-bottom: 5px;
                    //   box-shadow: 1px 1px 10px 1px gainsboro;
                    //   transition: 0.5s;
                    //   overflow: hidden;
                    //   float: left;
                    //   margin-left: 55px;
                    //   .cldnImg {
                    //     width: 150px;
                    //     height: 70px;
                    //     position: absolute;
                    //     bottom: 10px;
                    //     left: 10px;
                    //     .cdiImg {
                    //       width: 100%;
                    //       height: 100%;
                    //     }
                    //   }
                    //   .clMask {
                    //     width: 100%;
                    //     height: 250px;
                    //     background-color: #e4e7ed70;
                    //     opacity: 0;
                    //     text-align: center;
                    //     border-radius: 20px;
                    //     position: absolute;
                    //     transition: 0.5s;
                    //     top: 0px;
                    //     .cmCode {
                    //       width: 120px;
                    //       height: 120px;
                    //       margin-top: 50px;
                    //       border-radius: 20px;
                    //     }
                    //     .cmP {
                    //       text-align: center;
                    //       // color: white;
                    //       font-weight: 900;
                    //       font-size: 30px;
                    //     }
                    //   }
                    // }
                    // .clcLi3 {
                    //   width: 19%;
                    //   height: 430px;
                    //   position: relative;
                    //   border-radius: 20px;
                    //   margin-top: 30px;
                    //   margin-bottom: 5px;
                    //   float: left;
                    //   margin-left: 10px;
                    //   box-shadow: 1px 1px 10px 1px gainsboro;
                    //   transition: 0.5s;
                    //   overflow: hidden;
                    //   .cldnImg {
                    //     width: 70px;
                    //     height: 45px;
                    //     position: absolute;
                    //     bottom: 10px;
                    //     left: 10px;
                    //     overflow: hidden;
                    //     .cdiImg {
                    //       width: 100%;
                    //       height: 100%;
                    //     }
                    //   }
                    //   // 遮罩层
                    //   .clMask3 {
                    //     width: 100%;
                    //     height: 430px;
                    //     // background-color: rgba(107, 104, 104, 0.5);
                    //     background-color: #e4e7ed70;
                    //     opacity: 0;
                    //     border-radius: 20px;
                    //     position: absolute;
                    //     transition: 0.5s;
                    //     top: 0px;
                    //     .clmCode {
                    //       width: 100px;
                    //       height: 100px;
                    //       margin: 0 auto;
                    //       line-height: 100px;
                    //       text-align: center;
                    //       .clmP {
                    //         font-size: 20px;
                    //         font-weight: 900;
                    //         // color: white;
                    //         margin-top: 80px;
                    //       }
                    //       img {
                    //         width: 100%;
                    //         height: 100%;
                    //         border-radius: 10px;
                    //         margin-top: 20px;
                    //       }
                    //     }
                    //   }
                    // }
                    // .clcLi3:hover {
                    //   .clName,
                    //   .cldnImg {
                    //     opacity: 0;
                    //   }
                    //   .clMask3 {
                    //     opacity: 1;
                    //     // transform: translateY(-50px);
                    //   }
                    // }
                    // .clcLi4 {
                    //   width: 28%;
                    //   height: 250px;
                    //   position: relative;
                    //   border-radius: 20px;
                    //   margin-top: 30px;
                    //   margin-bottom: 5px;
                    //   box-shadow: 1px 1px 10px 1px gainsboro;
                    //   transition: 0.5s;
                    //   overflow: hidden;
                    //   float: left;
                    //   margin-left: 55px;
                    //   .clBg {
                    //     width: 100%;
                    //     height: 100%;
                    //     .bgImg {
                    //       border-radius: 20px;
                    //       width: 100%;
                    //       height: 100%;
                    //     }
                    //   }
                    //   .cldnImg {
                    //     width: 120px;
                    //     height: 70px;
                    //     position: absolute;
                    //     bottom: 10px;
                    //     right: 10px;
                    //     .cdiImg {
                    //       width: 100%;
                    //       height: 100%;
                    //     }
                    //   }
                    //   .clMask {
                    //     width: 100%;
                    //     height: 250px;
                    //     // background-color: rgba(107, 104, 104, 0.5);
                    //     background-color: #e4e7ed70;
                    //     opacity: 0;
                    //     text-align: center;
                    //     border-radius: 20px;
                    //     position: absolute;
                    //     transition: 0.5s;
                    //     top: 0px;
                    //     .cmCode {
                    //       width: 120px;
                    //       height: 120px;
                    //       margin-top: 50px;
                    //       border-radius: 20px;
                    //     }
                    //     .cmP {
                    //       text-align: center;
                    //       // color: white;
                    //       font-weight: 900;
                    //       font-size: 30px;
                    //     }
                    //   }
                    // }
                    // .clcLi5 {
                    //   width: 28%;
                    //   height: 250px;
                    //   position: relative;
                    //   border-radius: 20px;
                    //   margin-top: 30px;
                    //   margin-bottom: 5px;
                    //   box-shadow: 1px 1px 10px 1px gainsboro;
                    //   transition: 0.5s;
                    //   overflow: hidden;
                    //   float: left;
                    //   margin-left: 55px;
                    //   .cldnImg {
                    //     width: 130px;
                    //     height: 70px;
                    //     position: absolute;
                    //     bottom: 10px;
                    //     right: 10px;
                    //     .cdiImg {
                    //       width: 100%;
                    //       height: 100%;
                    //     }
                    //   }
                    //   .clMask {
                    //     width: 100%;
                    //     height: 250px;
                    //     // background-color: rgba(107, 104, 104, 0.5);
                    //     background-color: #e4e7ed70;
                    //     opacity: 0;
                    //     text-align: center;
                    //     border-radius: 20px;
                    //     position: absolute;
                    //     transition: 0.5s;
                    //     top: 0px;
                    //     .cmCode {
                    //       width: 120px;
                    //       height: 120px;
                    //       margin-top: 50px;
                    //       border-radius: 20px;
                    //     }
                    //     .cmP {
                    //       text-align: center;
                    //       // color: white;
                    //       font-weight: 900;
                    //       font-size: 30px;
                    //     }
                    //   }
                    // }
                    .clcLi1 {
                        width: 28%;
                        height: 250px;
                        position: relative;
                        border-radius: 20px;
                        margin-top: 30px;
                        margin-bottom: 5px;
                        box-shadow: 1px 1px 10px 1px #dcdcdc;
                        transition: 0.5s;
                        overflow: hidden;
                        float: left;
                        margin-left: 55px;
                        .clBg {
                            width: 100%;
                            height: 100%;
                            .bgImg {
                                border-radius: 20px;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .cldnImg {
                            width: 150px;
                            height: 70px;
                            position: absolute;
                            bottom: 10px;
                            left: 10px;
                            .cdiImg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .clMask {
                            width: 100%;
                            height: 250px;
                            background-color: rgba(228, 231, 237, 0.4392156862745098);
                            opacity: 0;
                            text-align: center;
                            border-radius: 20px;
                            position: absolute;
                            transition: 0.5s;
                            top: 0;
                            .cmCode {
                                width: 120px;
                                height: 120px;
                                margin-top: 50px;
                                border-radius: 20px;
                            }
                            .cmP {
                                text-align: center;
                                font-weight: 900;
                                font-size: 30px;
                            }
                        }
                    }
                    .clcLi1:hover {
                        transform: translateY(-10px);
                        box-shadow: 1px 1px 10px 1px gray;
                        .clMask {
                            opacity: 1;
                        }
                        .cldnImg {
                            opacity: 0;
                        }
                    }
                    .clcLi2 {
                        width: 19%;
                        height: 430px;
                        position: relative;
                        border-radius: 20px;
                        margin-top: 30px;
                        margin-bottom: 5px;
                        box-shadow: 1px 1px 10px 1px #dcdcdc;
                        transition: 0.5s;
                        overflow: hidden;
                        float: left;
                        margin-left: 10px;
                        .clBg {
                            width: 100%;
                            height: 100%;
                            .bgImg {
                                border-radius: 20px;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .cdiImg {
                            width: 70px;
                            height: 45px;
                            position: absolute;
                            bottom: 10px;
                            left: 10px;
                            overflow: hidden;
                            .cdiImg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        // 遮罩层
                        .clMask {
                            width: 100%;
                            height: 430px;
                            background-color: rgba(228, 231, 237, 0.4392156862745098);
                            opacity: 0;
                            border-radius: 20px;
                            position: absolute;
                            transition: 0.5s;
                            top: 0;
                            .cmCode {
                                width: 120px;
                                height: 120px;
                                display: block;
                                margin: 0 auto;
                                margin-top: 50px;
                                border-radius: 20px;
                            }
                            .cmP {
                                margin-top: 20px;
                                text-align: center;
                                font-weight: 900;
                                font-size: 30px;
                            }
                        }
                    }
                    .clcLi2:hover {
                        transform: translateY(-10px);
                        .cldnImg {
                            opacity: 0;
                        }
                        .clMask {
                            opacity: 1;
                            // transform: translateY(-50px);
                        }
                    }

                    li:hover {
                        transform: translateY(-10px);
                        box-shadow: 1px 1px 10px 1px gray;
                        .clMask {
                            opacity: 1;
                        }
                        .cldnImg,
                        .clName {
                            opacity: 0;
                        }
                    }
                }
            }
            .claseList2 {
                width: 100%;
                height: 100%;
                .allUl {
                    width: 24%;
                    min-height: 250px;
                    float: left;
                    .cl2cLi1 {
                        width: 320px;
                        height: 250px;
                        position: relative;
                        border-radius: 20px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        transition: 0.5s;
                        overflow: hidden;
                        float: left;
                        margin-left: 20px;
                        .clBg {
                            width: 100%;
                            height: 100%;
                            .bgImg {
                                border-radius: 20px;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .cldnImg {
                            width: 120px;
                            height: 70px;
                            position: absolute;
                            bottom: 10px;
                            right: 10px;

                            .cdiImg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .clMask {
                            width: 100%;
                            height: 250px;
                            background-color: #e4e7ed70;
                            opacity: 0;
                            text-align: center;
                            border-radius: 20px;
                            position: absolute;
                            top: 0px;
                            transition: 0.5s;
                            .cmCode {
                                width: 120px;
                                height: 120px;
                                margin-top: 50px;
                                border-radius: 20px;
                            }
                            .cmP {
                                text-align: center;
                                // color: white;
                                font-weight: 900;
                                font-size: 30px;
                            }
                        }
                    }
                    .cl2cLi1:hover {
                        transform: translateY(-10px);
                        box-shadow: 1px 1px 10px 1px gray;
                        .clMask {
                            opacity: 1;
                        }
                        .cldnImg {
                            opacity: 0;
                        }
                    }
                    .cl2cLi2 {
                        width: 320px;
                        height: 430px;
                        position: relative;
                        border-radius: 20px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        float: left;
                        margin-left: 10px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        transition: 0.5s;
                        overflow: hidden;
                        margin-left: 20px;
                        .clBg {
                            width: 100%;
                            height: 100%;
                            .bgImg {
                                border-radius: 20px;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .cdiImg {
                            width: 70px;
                            height: 45px;
                            position: absolute;
                            bottom: 10px;
                            left: 10px;
                            overflow: hidden;
                            .cdiImg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        // 遮罩层
                        .clMask {
                            width: 100%;
                            height: 430px;
                            background-color: #e4e7ed70;
                            opacity: 0;
                            border-radius: 20px;
                            position: absolute;
                            transition: 0.5s;
                            top: 0px;
                            .cmCode {
                                width: 120px;
                                height: 120px;
                                display: block;
                                margin: 0 auto;
                                margin-top: 50px;
                                border-radius: 20px;
                            }
                            .cmP {
                                margin-top: 20px;
                                text-align: center;
                                font-weight: 900;
                                font-size: 30px;
                            }
                        }
                    }
                    .cl2cLi2:hover {
                        transform: translateY(-10px);
                        .cldnImg {
                            opacity: 0;
                        }
                        .clMask {
                            opacity: 1;
                            // transform: translateY(-50px);
                        }
                    }
                }
            }
        }
    }
}
</style>
